import React, { Suspense } from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom"

import FullPageLoading from './../components/FullPageLoading/index'

const ProtectedRoute = React.lazy(() => import('./../utils/ProtectedRoute'))
const Home = React.lazy(() => import('./../pages/Home'))
const Contact = React.lazy(() => import('./../pages/Contact'))
const MyAccount = React.lazy(() => import('./../pages/MyAccount'))
const ForgotPassword = React.lazy(() => import('./../pages/ForgotPassword'))
const ResetPassword = React.lazy(() => import('./../pages/ResetPassword'))
const Profile = React.lazy(() => import('./../pages/my-account/Profile'))
const ChangePassword = React.lazy(() => import('./../pages/my-account/ChangePassword'))
const Orders = React.lazy(() => import('./../pages/my-account/Orders'))
const OrderDetails = React.lazy(() => import('./../pages/my-account/OrderDetails'))
const MenuCompound = React.lazy(() => import('./../pages/MenuCompound'))
const ArticleCompound = React.lazy(() => import('./../pages/ArticleCompound'))
const Page = React.lazy(() => import('./../pages/Page'))
const OrderSuccess = React.lazy(() => import('./../pages/OrderSuccess'))
const OrderFailed = React.lazy(() => import('./../pages/OrderFailed'))
const Cart = React.lazy(() => import('./../pages/Cart'))
const Page404 = React.lazy(() => import('./../pages/Page404'))

const Navigation = () => {
    return (
        <Suspense fallback={<FullPageLoading />}>
            <Router>
                <Switch>
                    <Route exact path="/"><Home /></Route>
                    <Route path="/contact"><Contact /></Route>
                    <Route exact path="/mon-compte"><MyAccount /></Route>
                    <Route path="/forgot-password"><ForgotPassword /></Route>
                    <Route path="/reset-password/:token"><ResetPassword /></Route>
                    
                    <Route path="/cart"><Cart /></Route>
                    <Route path="/order-success"><OrderSuccess /></Route>
                    <Route path="/order-failed"><OrderFailed /></Route>

                    <Route path="/menu-composer"><MenuCompound /></Route>
                    <Route path="/article/:slug"><ArticleCompound /></Route>
                    <Route path="/page/:slug"><Page /></Route>

                    <ProtectedRoute path="/mon-compte/profil" component={ Profile } />
                    <ProtectedRoute path="/mon-compte/change-password" component={ ChangePassword } />
                    <ProtectedRoute path="/mon-compte/mes-commandes" component={ Orders } />
                    <ProtectedRoute exact path="/mon-compte/ma-commande/:id" component={ OrderDetails } />
                    <Route><Page404 /></Route>
                </Switch>
            </Router>
        </Suspense>
    )
}

export default Navigation
