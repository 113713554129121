import React, { createContext, useState, useEffect } from 'react'
import UseFetch from './../hooks/useFetch'

export const ApplicationContext = createContext()

export const ApplicationContextProvider = ({ children }) => {
    const [menu, setMenu] = useState(false)
    const [general, setGeneral] = useState(null)
    const [config, setConfig] = useState(null)
    const [customization, setCustomization] = useState(null)
    const [socialMedia, setSocialMedia] = useState(null)
    const [contact, setContact] = useState(null)
    const [primary, setPrimaryMenu] = useState(null)
    const [navigation, setNavigation] = useState(null)
    const [account, setAccount] = useState(null)

    const [contactResponse, setContactResponse] = useState(null)
    const [contactLoading, setContactLoading] = useState(false)
    const [areas, setAreas] = useState(null)

    const [next, setNext] = useState(0)
    const [asyncFetch , {response, error, success, type}] = UseFetch()

    useEffect(() => {
        asyncFetch({ url: '/settings', type: 'settings', method: 'get' })
    }, [])

    useEffect(() => {
        if(next === 1) asyncFetch({ url: '/menu', type: 'menu', method: 'get' })
        else if(next === 2) asyncFetch({ url: '/areas', type: 'areas', method: 'get' })
    }, [next])

    useEffect(() => {
        if(general && general.store_name) document.title = general.store_name
        if(general && general.store_favicon){
            let favicon = document.getElementById('favicon')
            favicon.href = general.store_favicon
        }
    }, [general])

    useEffect(() => {
        // document.title = general.store_name
    }, [config])

    useEffect(() => {
        // document.title = general.store_name
        if(customization && customization.primary_color) document.documentElement.style.setProperty('--primary', customization.primary_color);
        if(customization && customization.second_color) document.documentElement.style.setProperty('--second', customization.second_color);
        if(customization && customization.title_color) document.documentElement.style.setProperty('--title', customization.title_color);
        if(customization && customization.text_color) document.documentElement.style.setProperty('--text', customization.text_color);
        
    }, [customization])

    useEffect(() => {
        switch(type){
            case 'settings':
                setGeneral(response['general'] ?? {})
                setConfig(response['config'] ?? {})
                setCustomization(response['customization'] ?? {})
                setSocialMedia(response['socialMedia'] ?? {})
                setContact(response['contact'] ?? {})
                setNext(1)
            break;
            case 'menu':
                setPrimaryMenu(response['primaryMenu'] ?? {})
                setNavigation(response['navigation'] ?? {})
                setAccount(response['account'] ?? {})
                setNext(2)
            break;
            case 'areas':
                setAreas(response ?? null)
                setNext(3)
            break;
        }
    }, [response])

    useEffect(() => {
        if(type === 'contact-us'){
            setContactLoading(false)
            setContactResponse({
                type: 'success',
                message: success
            })
        }
    }, [success])

    useEffect(() => {
        if(type === 'contact-us'){
            setContactLoading(false)
            setContactResponse({
                type: 'error',
                message: error
            })
        }
    }, [error])

    const handleToggleMenu = () => {
        setMenu(menu => !menu)
    }
    const submitContactForm = (values) => {
        setContactLoading(true)
        asyncFetch({ url: '/contact-us', type: 'contact-us', method: 'POST', body: JSON.stringify(values) })
    }

    return (
        <ApplicationContext.Provider value={{ 
            menu, handleToggleMenu, 
            general, config, customization, socialMedia, contact, areas,
            submitContactForm, contactResponse, contactLoading, 
            primary, navigation, account
        }}>
            {children}
        </ApplicationContext.Provider>
    )
}
