import { useState, useCallback } from 'react'
import { Bash_url, ApiKey } from './../utils/General'

const UseFetch = () => {
    const [response, setResponse] = useState([])
    const [errorData, setErrorData] = useState([])
    const [error, setError] = useState(null)
    const [success, setSuccess] = useState(null)
    const [type, setType] = useState(null)
    const [loading, setLoading] = useState(false)
    const [authStatus, setAuthStatus] = useState(null)

    const asyncFetch = useCallback(async ({ url, type, ...options }) => {
        setType(type)
        if(!response) setResponse([])
        if(error) setError(null)
        if(success) setSuccess(null)
        if(!loading) setLoading(true)
        if(authStatus) setAuthStatus(null)

        try{
            const res = await fetch(Bash_url + url, {headers: {'Accept': 'application/json', 'Content-Type': 'application/json', apikey: ApiKey, 'Authorization': 'Bearer ' + localStorage.getItem('r-token')}, ...options})
            const json = await res.json()
            
            if(json.status){
                setTimeout(() => {
                    setResponse(json.data)
                    // if(json.data) setResponse(json.data)
                    if(json.message) setSuccess(json.message)
                    setLoading(false)
                }, 500);
            }else{
                if(json.logout) setAuthStatus(true)
                setTimeout(() => {
                    if(json.error) setError(json.error)
                    if(json.message) setError(json.message)
                    if(json.data) setErrorData(json.data)
                    setLoading(false) 
                }, 500);
            }
        }catch(e){
            setLoading(false) 
            setError('Un problème technique a été détecté, Veuillez réessayer plus tard?')
        }
    },[])

    return [asyncFetch , { response, error, success, type, loading, errorData, authStatus }];
}

export default UseFetch
