import React, { createContext, useState, useEffect } from 'react'
import UseFetch from './../hooks/useFetch'

export const UserContext = createContext()

export const UserContextProvider = ({ children }) => {
    const [isAuth, setIsAuth] = useState(false)
    const [user, setUser] = useState(null)
    
    const [signResponse, setSignResponse] = useState(null)
    const [signLoading, setSignLoading] = useState(false)

    const [asyncFetch , {response, error, success, type, loading, authStatus}] = UseFetch()

    useEffect(() => {
        let token = localStorage.getItem('r-token')
        if(token){
            setIsAuth(true)
            fetchUserInfos(token)
        }else{
            if(isAuth) setIsAuth(false)
            if(user) setUser(null)
        }
    }, [])

    useEffect(() => {
        switch(type){
            case 'customer-information':
                if(!isAuth) setIsAuth(true)
                setUser(response)
            break;
            case 'customer-login':
                saveToken(response.access_token)
                setIsAuth(true)
                fetchUserInfos()
            break;
            case 'customer-register':
                saveToken(response.access_token)
                setIsAuth(true)
                fetchUserInfos()
            break;
            default:
            break;
        }
    }, [response])

    useEffect(() => {
        switch(type){
            case 'customer-login':
                setSignResponse({
                    for: type,
                    type: 'success',
                    message: 'Vous vous êtes connecté avec succès'
                })
            break;
            case 'customer-register':
                setSignResponse({
                    for: type,
                    type: 'success',
                    message: 'Vous vous êtes inscrit avec succès'
                })
            break;
            case 'customer-forgot-password':
                setSignResponse({
                    for: type,
                    type: 'success',
                    message: success
                })
            break;
            case 'customer-reset-password':
                setSignResponse({
                    for: type,
                    type: 'success',
                    message: success
                })
            break;
            case 'customer-update-password':
                setSignResponse({
                    for: type,
                    type: 'success',
                    message: success
                })
            break;
            case 'customer-update':
                setSignResponse({
                    for: type,
                    type: 'success',
                    message: success
                })
            break;
            default:
            break;
        }
    }, [success])

    useEffect(() => {
        switch(type){
            case 'customer-login':
                setSignResponse({
                    for: type,
                    type: 'error',
                    message: error
                })
            break;
            case 'customer-register':
                setSignResponse({
                    for: type,
                    type: 'error',
                    message: error
                })
            break;
            case 'customer-forgot-password':
                setSignResponse({
                    for: type,
                    type: 'error',
                    message: error
                })
            break;
            case 'customer-reset-password':
                setSignResponse({
                    for: type,
                    type: 'error',
                    message: error
                })
            break;
            case 'customer-update-password':
            case 'customer-update':
                setSignResponse({
                    for: type,
                    type: 'error',
                    message: error
                })
            break;
            case 'customer-information':
                if(isAuth) setIsAuth(false)
                if(user) setUser(null)
                localStorage.removeItem('r-token')
            break;
            default:
            break;
        }
    }, [error])

    useEffect(() => {
        if(authStatus){
            localStorage.removeItem('r-token')
            if(isAuth) setIsAuth(false)
            if(user) setUser(null)
        }
    }, [authStatus])

    useEffect(() => {
        setSignLoading(loading)
    }, [loading])

    useEffect(() => {
        const timer = setTimeout(() => setSignResponse(null), 4000);
        return () => clearTimeout(timer);
    }, [signResponse])

    const saveToken = (access_token) => {
        localStorage.setItem('r-token', access_token)
    }

    const SignUpFacebook = (credentials) => {
        asyncFetch({ url: '/customer-login-facebook', type: 'customer-register', method: 'POST', body: JSON.stringify(credentials) })
    }

    const SignUpGoogle = (credentials) => {
        asyncFetch({ url: '/customer-login-google', type: 'customer-register', method: 'POST', body: JSON.stringify(credentials) })
    }

    const fetchUserInfos = () => {
        setSignLoading(true)
        asyncFetch({ url: '/customer-informations', type: 'customer-information', method: 'GET' })
    }

    const submitLoginForm = (values) => {
        setSignLoading(true)
        asyncFetch({ url: '/customer-login', type: 'customer-login', method: 'POST', body: JSON.stringify(values) })
    }

    const submitRegisterForm = (values) => {
        setSignLoading(true)
        asyncFetch({ url: '/customer-register', type: 'customer-register', method: 'POST', body: JSON.stringify(values) })
    }
    
    const submitForgotPasswordForm = (values) => {
        setSignLoading(true)
        asyncFetch({ url: '/customer-forgot-password', type: 'customer-forgot-password', method: 'POST', body: JSON.stringify(values) })
    }

    const submitResetPasswordForm = (values) => {
        setSignLoading(true)
        asyncFetch({ url: '/customer-reset-password', type: 'customer-reset-password', method: 'POST', body: JSON.stringify(values) })
    }

    const submitUpdatePassword = (values) => {
        asyncFetch({ url: '/customer-update-password', type: 'customer-update-password', method: 'POST', body: JSON.stringify(values) })
    }

    const submitCustomerUpdate = (values) => {
        asyncFetch({ url: '/customer-update', type: 'customer-update', method: 'POST', body: JSON.stringify(values) })
    }

    const logout = (values) => {
        localStorage.removeItem('r-token')
        setIsAuth(false)
        asyncFetch({ url: '/customer-logout', type: 'customer-logout', method: 'POST', body: JSON.stringify(localStorage.getItem('r-token')) })
    }

    return (
        <UserContext.Provider value={{
            isAuth, user, 
            submitLoginForm, submitRegisterForm, signResponse, signLoading, SignUpFacebook, SignUpGoogle, 
            submitForgotPasswordForm, submitResetPasswordForm, type,
            submitUpdatePassword, submitCustomerUpdate, logout
        }}>
            {children}
        </UserContext.Provider>
    )
}
