import React from 'react'
import './style.css'

const Index = () => {    
    return (
        <div className="full-loading">
            <div className="load">
                <div className="load-one"></div>
                <div className="load-two"></div>
                <div className="load-three"></div>
            </div>
            {/* <div className="loading"></div> */}
        </div>
    )
}

export default Index