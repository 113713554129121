import 'bootstrap/dist/css/bootstrap.min.css'
import './utils/general.css'

import React from 'react'
import { QueryClient, QueryClientProvider } from "react-query";

import { ApplicationContextProvider } from './contexts/ApplicationContext'
import { UserContextProvider } from './contexts/UserContext'
import { CartContextProvider } from './contexts/CartContext'
import Navigation from './layouts/Navigation'

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ApplicationContextProvider>
        <UserContextProvider>
          <CartContextProvider>
            <Navigation />
          </CartContextProvider>
        </UserContextProvider>
      </ApplicationContextProvider>
    </QueryClientProvider>
  );
}

export default App;
