import React from 'react'

// export const Bash_url = 'https://restaurant-admin.test/api'
// export const Url = 'https://restaurant-admin.test/'

export const Bash_url = 'https://admin-demo.intuitivedesign.ma/api'
export const Url = 'https://admin-demo.intuitivedesign.ma/'

export const ApiKey = ']$5v>NNe_5CaZ]N'
export const GoogleMapToken = 'AIzaSyBvzkkdirdejZa90gR0HFNlr_YFF86LwtE'

export const statusWrapper = (status) => {
    if(status === 'preparation') return <span className="text-red">En préparation</span>
    if(status === 'ready') return <span className="text-warning">Prêt</span>
    if(status === 'accepted') return <span className="text-warning">Accepter</span>
    if(status === 'delivered') return <span className="text-success">Livrée</span>
    if(status === 'cancel') return <span className="text-gray">Annulée</span>
    return <span className="text-info">Nouveaux</span>
}

export const getMenuLink = (item) => {
    let link = ''
    if(item.type === 'url') link = item.link
    else if(item.type === 'page') link = '/page/'+item.link
    else if(item.type === 'article') link = '/article/'+item.link

    return link
}