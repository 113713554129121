import React, { createContext, useState, useContext, useEffect } from 'react'
import UseFetch from './../hooks/useFetch'
import {Url} from './../utils/General'

import { UserContext } from './UserContext'
import { ApplicationContext } from './ApplicationContext'

export const CartContext = createContext()
const initialCart = [{articles: [], subtotal: 0, total: 0, shipment: 0 }]

export const CartContextProvider = ({ children }) => {
    const { isAuth } = useContext(UserContext)
    const { general } = useContext(ApplicationContext)

    const [ cartResponse, setCartResponse ] = useState(null)
    const [ cartLocalStorage, setCartLocalStorage ] = useState([])
    const [ cart, setCart ] = useState(initialCart)
    const [ loadingForm, setLoadingForm ] = useState(true)
    const [ changeCart, setChangeCart ] = useState(true)
    
    const [ coupon, setCoupoon ] = useState(null)
    const [ typeDelivery, setTypeDelivery ] = useState(null)
    const [ timing, setTiming ] = useState(null)

    const [ address, setAddress ] = useState(null)
    const [ attitude, setAttitude ] = useState(null)

    const [ payment, setPayment ] = useState(null)
    const [ comment, setComment ] = useState('')

    const [ redirect, setRedirect ] = useState(false)

    const [asyncFetch , {response, error, success, type, loading, errorData}] = UseFetch()
    
    useEffect(() => {
        try{
            let local = localStorage.getItem('r-cart')
            if(!local || !Array.isArray(JSON.parse(local))){
                localStorage.setItem('r-cart', JSON.stringify([]))
            }else{
                let cartStorage = JSON.parse(localStorage.getItem('r-cart'))
                if(cartStorage && cartStorage.length > 0){
                    // console.log('setCartLocalStorage 1', cartStorage)
                    setCartLocalStorage(cartStorage)
                    let coupon = localStorage.getItem('r-coupon')
                    if(coupon) setCoupoon(localStorage.getItem('r-coupon'))
                }else{
                    setCoupoon(null)
                    localStorage.removeItem('r-coupon')
                }
            }
        }catch(e){
            localStorage.setItem('r-cart', JSON.stringify([]))
        }
    }, [])

    const getDataFitch = (cart, coupon = null, currentAddress = null) => {
        let body = {cart: (typeof cart === 'string') ? JSON.parse(cart) : cart}
        if(coupon) body = {...body, ...{coupon: coupon}}
        if(typeDelivery) body = {...body, ...{type: typeDelivery}}
        
        if(currentAddress) body = {...body, ...{address: currentAddress}}
        else if(address) body = {...body, ...{address: address}}
        
        return body;
    }

    useEffect(() => {
        // console.log('handleAddToCart', cartLocalStorage, changeCart)
        localStorage.setItem('r-cart', JSON.stringify(cartLocalStorage))
        if(changeCart){
            if(cartLocalStorage.length > 0){
                let cartFn = getDataFitch(cartLocalStorage, localStorage.getItem('r-coupon'))
                // let cartFn = getDataFitch(localStorage.getItem('r-cart'), localStorage.getItem('r-coupon'))
                // console.log('start get-cart')
                asyncFetch({ url: '/get-cart', type: 'getCart', method: 'POST', body: JSON.stringify( cartFn ) })
            }else{
                // console.log('setCart initialCart')
                setCart(initialCart)
            }
        }else{
            setChangeCart(true)
            // if(loadingForm) setLoadingForm(false)
        }
    }, [cartLocalStorage])

    useEffect(() => {
        if((type === 'getCart' || type === 'Order' || type === 'OrderGuest') && errorData){
            // console.log('update cart ', errorData)
            localStorage.setItem('r-cart', JSON.stringify(errorData))
            let cartFn = getDataFitch(errorData, localStorage.getItem('r-coupon'))
            asyncFetch({ url: '/get-cart', type: 'getCart', method: 'POST', body: JSON.stringify(cartFn) })
        }
    }, [errorData])

    useEffect(() => {
        switch(type){
            case 'getCart':
                // console.log('here get-cart cartLocalStorage')
                if(localStorage.getItem('r-cart')){
                    let local = JSON.parse(localStorage.getItem('r-cart'))
                    if( local && local.length > 0){
                        // console.log('response.articles => ', response.articles)
                        // console.log('response.newCart => ', response.newCart)

                        if(response && response.articles){ //  && response.articles.length > 0
                            // console.log('inside if response.articles')
                            if(response.newCart){
                                // console.log('inside if response.newCart')
                                setChangeCart(false)
                                setCart(response)
                                setCartLocalStorage(response.newCart)
                                if((response.newCart && response.newCart.length < 1) || (response.coupon === 0 || !response.coupon_details)){
                                    setCoupoon(null)
                                    localStorage.removeItem('r-coupon')
                                }else{
                                    setCoupoon(localStorage.getItem('r-coupon'))
                                }
                            }else{
                                // console.log('inside else response.newCart')
                                setCart(response)
                                if((cartLocalStorage && cartLocalStorage.length < 1) || (response.coupon === 0 || !response.coupon_details)){
                                    setCoupoon(null)
                                    localStorage.removeItem('r-coupon')
                                }else{
                                    setCoupoon(localStorage.getItem('r-coupon'))
                                }
                            }
                        }else{
                            // console.log('inside else response.articles')
                        }
                    }else{
                        setCart(initialCart)
                        setCartLocalStorage([])
                    }

                    if(loadingForm) {
                        setLoadingForm(false)
                        // console.log('loading', loading)
                    }
                }
            break;
            case 'getNewCalculations':
                // console.log('getNewCalculations check')
                if(localStorage.getItem('r-cart')){
                    let local = JSON.parse(localStorage.getItem('r-cart'))
                    if( local && local.length > 0){
                        if(response && response.articles && response.articles.length > 0){
                            setCart(response)
                            if((cartLocalStorage && cartLocalStorage.length < 1) || (response.coupon === 0 || !response.coupon_details)){
                                setCoupoon(null)
                                localStorage.removeItem('r-coupon')
                            }else{
                                setCoupoon(localStorage.getItem('r-coupon'))
                            }
                        }
                    }else{
                        setCart(initialCart)
                        setCartLocalStorage([])
                    }
                    if(loadingForm) setLoadingForm(false)
                }
            break;
            case 'check-coupon':
                    setCoupoon(response.code)
                    localStorage.setItem('r-coupon', response.code)
                    let cartFn = getDataFitch(localStorage.getItem('r-cart') , response.code) 
                    asyncFetch({ url: '/get-cart', type: 'getCart', method: 'POST', body: JSON.stringify(cartFn) })
                break;
            case 'Order':
                if(response){
                    if(response.mode === 'card'){
                        window.location.href = Url + '/complete-payment/' + response.id;
                    }else{
                        setTimeout(() => {
                            clearAll()
                            setRedirect(true)
                            if(loadingForm) setLoadingForm(false)
                        }, 500);
                    }
                }
                break;
            case 'OrderGuest':
                if(response){
                    if(response.mode === 'card'){
                        window.location.href = Url + '/complete-payment/' + response.id;
                    }else{
                        setTimeout(() => {
                            clearAll()
                            setRedirect(true)
                            if(loadingForm) setLoadingForm(false)
                        }, 500);
                    }
                }
                break;
            default:
            break;
        }
    }, [response])

    useEffect(() => {
        switch(type){
            case 'check-coupon':
                setCartResponse({
                    for: type,
                    type: 'error',
                    message: error
                })
                if(loadingForm) setLoadingForm(false)
            break;
            case 'Order':
                setCartResponse({
                    for: type,
                    type: 'error',
                    message: error
                })
                if(loadingForm) setLoadingForm(false)
            break;
            case 'OrderGuest':
                setCartResponse({
                    for: type,
                    type: 'error',
                    message: error
                })
                if(loadingForm) setLoadingForm(false)
            break;
            default:
            break;
        }
    }, [error])
    
    useEffect(() => {
        // console.log('success')
        setCartResponse(success)
        if(loadingForm) setLoadingForm(false)
    }, [success])

    useEffect(() => {
        setLoadingForm(loading)
    }, [loading])

    useEffect(() => {
        const timer = setTimeout(() => setCartResponse(null), 4000);
        return () => clearTimeout(timer);
    }, [cartResponse])
    
    useEffect(() => {
        if(!typeDelivery){
            let cartFn = getDataFitch(localStorage.getItem('r-cart'), localStorage.getItem('r-coupon'))
            asyncFetch({ url: '/get-cart', type: 'getNewCalculations', method: 'POST', body: JSON.stringify(cartFn) })
        } 
    }, [typeDelivery])

    const clearAll = () => {
        // console.log('clearAll')
        localStorage.removeItem('r-coupon')
        localStorage.setItem('r-cart', JSON.stringify([]))
        if(cartLocalStorage.length > 0) setCartLocalStorage([])
        if(payment) setPayment(null)
        if(coupon) setCoupoon(null)
        if(typeDelivery) setTypeDelivery(null)
        if(comment) setComment('')
        if(comment) setComment('')
        if(address) setAddress(null)
        if(timing) setTiming(null)
        if(comment) setComment('')
        if(attitude) setAttitude(null)
    }

    // Delivery Type
    const handleTypeDelivery = (item) => {
        setTypeDelivery(item)
    }

    // Location
    const handleMap = (latLat, latLng, newAddress) => {
        setAttitude({lat: latLat, lng: latLng})
        setAddress(newAddress)
    }
    const handleAddress = (item) => {
        setAddress(item)
        let cartFn = getDataFitch(localStorage.getItem('r-cart'), localStorage.getItem('r-coupon'), item)
        if(item) asyncFetch({ url: '/get-cart', type: 'getNewCalculations', method: 'POST', body: JSON.stringify(cartFn) })
    }

    // Time
    const handleTiming = (item) => {
        setTiming(item)
        // console.log('fitch getNewCalculations time')
        let cartFn = getDataFitch(localStorage.getItem('r-cart'), localStorage.getItem('r-coupon'))
        if(item) asyncFetch({ url: '/get-cart', type: 'getNewCalculations', method: 'POST', body: JSON.stringify(cartFn) })
    }

    // Coupon
    const handleCouponAdded = (code) => {
        if(!loadingForm) setLoadingForm(true)
        asyncFetch({ url: '/check-coupon', type: 'check-coupon', method: 'POST', body: JSON.stringify({code: code, subtotal: cart.subtotal}) })
    }
    const handleCouponRemoved = () => {
        if(localStorage.getItem('r-coupon')){
            setCoupoon(null)
            localStorage.removeItem('r-coupon')
            let cartFn = getDataFitch(localStorage.getItem('r-cart'))
            asyncFetch({ url: '/get-cart', type: 'getCart', method: 'POST', body: JSON.stringify(cartFn) })
        }
    }

    // payment
    const handlePayment = (item) => {
        setPayment(item)
    }

    // Comment
    const handleComment = (item) => {
        setComment(item)
    }

    // Cart
    const handleAddToCart = (product_id, typeDelivery = 'simple', details = []) => {
        // console.log("===================================")
        // console.log(typeDelivery)
        if(typeDelivery === 'simple'){
            let exists = false
            let newLocal = cartLocalStorage.map((row, key) => {
                if(row.product === product_id){
                    cartLocalStorage[key].qty += 1
                    exists = true
                }
                return row
            });
            if(exists) {
                setCartLocalStorage(newLocal)
                // console.log('exists')
                // console.log('cart ', newLocal)
            }else {
                newLocal = [ ...cartLocalStorage, {'product': product_id, qty: 1, details: []}]
                setCartLocalStorage(newLocal)
                // console.log('not exists')
                // console.log('cart ', newLocal)
            }
        }

        if(typeDelivery === 'compound'){
            let exists = false
            let newLocal = cartLocalStorage.map((row, key) => {
                if(row.product === product_id && row.details && JSON.stringify(row.details) === JSON.stringify(details) ){
                    cartLocalStorage[key].qty += 1
                    exists = true
                }
                return row
            });
            if(exists){
                setCartLocalStorage(newLocal)
            } else{
                const newLocalStorage = [ ...cartLocalStorage, {'product': product_id, qty: 1, details: details}]
                setCartLocalStorage( newLocalStorage )
            }
        }
            
    }
    const handleUpdateCart = (key, product_id, type) => {
        let newLocal = cartLocalStorage.filter((row, index) => {
            if(row.product === product_id && index === key){
                if(type === 'increase') cartLocalStorage[index].qty += 1
                if(type === 'decrease') cartLocalStorage[index].qty -= 1
            }
            if(row.qty > 0) return row
        });
        setCartLocalStorage(newLocal)
    }
    const handleRemoveFromCart = (key, product_id) => {
        let newLocal = cartLocalStorage.filter((row, index) => {
            if(row.product !== product_id && index !== key) return row
        });
        setCartLocalStorage(newLocal)
    }

    // Order
    const handleCommande = () => {
        if(general && general.opening){
            // if(isAuth && localStorage.getItem('r-token')){
                if(typeDelivery === 'delivery' || typeDelivery === 'take'){
                    if(payment === 'surplace' || payment === 'card'){
                        if(typeDelivery === 'delivery'){
                            if(!isAuth && address && address.name && address.telephone && address.full_address && address.telephone && address.quartier){
                                asyncFetch({ url: '/add-order-guest', type: 'OrderGuest', method: 'POST', body: JSON.stringify({cart: JSON.parse(localStorage.getItem('r-cart')), coupon: localStorage.getItem('r-coupon'), orderType: typeDelivery, comment: comment, address: address, attitude: attitude, payment: payment }) })
                            }else if(isAuth && address.full_address && address.telephone && address.quartier){
                                asyncFetch({ url: '/add-order', type: 'Order', method: 'POST', body: JSON.stringify({cart: JSON.parse(localStorage.getItem('r-cart')), coupon: localStorage.getItem('r-coupon'), orderType: typeDelivery, comment: comment, address: address, attitude: attitude, payment: payment }) })
                            }else{
                                setLoadingForm(false)
                                setCartResponse({
                                    type: 'errorOrder',
                                    message: 'Vous devez ajouter votre adresse de la livraison'
                                })
                            }
                        }else if(typeDelivery === 'take'){
                            if(!isAuth && timing && timing.name && timing.telephone && timing.time){
                                asyncFetch({ url: '/add-order-guest', type: 'OrderGuest', method: 'POST', body: JSON.stringify({cart: JSON.parse(localStorage.getItem('r-cart')), coupon: localStorage.getItem('r-coupon'), orderType: typeDelivery, comment: comment, timing: timing, payment: payment }) })
                            }else if(isAuth && timing && timing.time){
                                asyncFetch({ url: '/add-order', type: 'Order', method: 'POST', body: JSON.stringify({cart: JSON.parse(localStorage.getItem('r-cart')), coupon: localStorage.getItem('r-coupon'), orderType: typeDelivery, comment: comment, timing: timing, payment: payment }) })
                            }else{
                                setLoadingForm(false)
                                setCartResponse({
                                    type: 'errorOrder',
                                    message: 'Vous devez sélectionner l\'heure de la commande' 
                                })
                            }
                        }else{
                            setLoadingForm(false)
                            setCartResponse({
                                type: 'errorOrder',
                                message: 'Il y a un problème, veuillez réessayer plus tard' 
                            })
                        }
                    }else{
                        setLoadingForm(false)
                        setCartResponse({
                            type: 'errorOrder',
                            message: 'Vous devez choisir le mode de paiement'
                        })
                    }

                }else{
                    setLoadingForm(false)
                    setCartResponse({
                        type: 'errorOrder',
                        message: 'Vous devez choisir le type de votre commande'
                    })
                    setTypeDelivery(null)
                }
            // }else{
            //     setLoadingForm(false)
            //     setCartResponse({
            //         type: 'errorOrder',
            //         message: 'Merci de se connectez avant de commander' 
            //     })
            // }
        }else{
            setLoadingForm(false)
            setCartResponse({
                type: 'errorOrder',
                message: 'Le restaurant est fermé on pourrait pas recevoir votre commande en ce moment'
            })
        }
    }

    return (
        <CartContext.Provider value={{
            cart, cartLocalStorage, 
            handleAddToCart, handleUpdateCart, handleRemoveFromCart, 
            handleCouponAdded, handleCouponRemoved, coupon, 
            handleTypeDelivery, typeDelivery,
            handleTiming, timing,
            handleMap, handleAddress, address,
            handlePayment, payment,
            handleComment, comment,
            loading, type,
            cartResponse, loadingForm,
            handleCommande, redirect, setRedirect

            // errorCart, setCartResponse, successCart, setCartResponse, 
            // typeDelivery, setTypeDelivery,
            // comment, setComment,
            // timing, setTiming,
            // payment, setPayment,
            // address, setAddress, setAttitude, subAddress, setSubAddress, 
            // cart, dataLoading, loadingForm, setLoadingForm, coupon, handleCouponAdded, handleCouponRemoved, handleAddToCart, redirect, setRedirect, handleUpdateCart, handleCommande
        }}>
            {children}
        </CartContext.Provider>
    )
}
